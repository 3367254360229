<!--
 * @Author: CL
 * @Date: 2021-06-24 20:33:58
 * @LastEditTime: 2021-06-24 23:17:02
 * @Description: 选择图标的组件
-->

<template>
  <div class="selecticon-contain" v-show="selectIsShow">
    <div class="icon-item">
      <span>首页</span>
      <IconFont type="home" />
    </div>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';

export default {
  data(){
    return {
      arr: [
        {
          id: 1,
          name: '首页'
        }
      ]
    }
  },

  components: {
    IconFont
  },

  props: {
    selectIsShow: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.selecticon-contain{
  position: absolute;
  bottom: -50%;
  transform: translateY(100%);
  left: 0;
  width: 100%;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  z-index: 999;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  height: 100px;
  overflow-y: scroll;

  &::before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fff;
    border-left: 1px solid #DCDFE6;
    border-top: 1px solid #DCDFE6;
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotateZ(45deg);
  }

  .icon-item{
    margin: 5px;
    display: flex;
    align-items: center;
  }
}
</style>
