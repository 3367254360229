<!--
 * @Author: CL
 * @Date: 2021-06-24 20:07:44
 * @LastEditTime: 2021-06-24 23:21:50
 * @Description: 添加导航信息模态框
-->

<template>
  <div class="addnavmodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="导航名称" prop="name">
          <el-input clearable class="cl-dialog" v-model="ruleForm.name" placeholder="必填，请输入导航名称"></el-input>
        </el-form-item>
        <el-form-item label="导航类型" prop="type">
          <el-input clearable class="cl-dialog" v-model="ruleForm.type" placeholder="必填，请输入导航类型标题"></el-input>
        </el-form-item>
        <!-- <el-form-item label="导航图标" prop="type">
          <div class="select-input" @click.stop="chooseIcon">
            请选择图标
            <SelectIcon :selectIsShow="selectIsShow" />
          </div>
        </el-form-item> -->
        <el-form-item label="跳转路径" prop="path">
          <el-input clearable class="cl-dialog" v-model="ruleForm.path" placeholder="必填,请输入导航跳转地址"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import SelectIcon from '../components/selectIcon.vue';
import { addNav } from '@/api/nav';

export default {
  data(){
    return {
      ruleForm: {
        name: '',    //导航名称
        type: '',    //导航类型
        path: '',    //跳转的路径
      },

      rules: {
        name: [
          { required: true, message: '导航名称不能为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '导航图标不能为空', trigger: 'blur' },
        ],
        path: [
          { required: true, message: '跳转路径不能为空', trigger: 'blur' },
        ],
      },

      selectIsShow: false
    }
  },

  mounted() {
    this.$bus.$on('emitClose', this.handleClose);
  },

  destroyed() {
    this.$bus.$off('emitClose', this.handleClose);
  },

  methods: {
    closeModel(){
      this.close();
    },

    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await addNav(this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: '添加成功'
            })
            this.close('refresh');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    close(data){
      this.$emit('closeAddNavModel', false, data);
      this.$refs['ruleForm'].resetFields();
    },

    /**
     * 打开图标选择框
     */
    chooseIcon(){
      this.selectIsShow = true;
    },

    /**
     * 关闭图标选择框
     */
    handleClose(){
      this.selectIsShow = false;
    }
  },

  components: {
    SelectIcon
  }
}
</script>

<style lang="less" scoped>
.addnavmodel-contain{
  .select-input{
    width: 100%;
    height: 40px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    position: relative;
    padding: 0 15px;
    color: rgb(180, 180, 180, 0.8);
    box-sizing: border-box;
  }
}
</style>
