<!--
 * @Author: CL
 * @Date: 2021-06-27 17:41:43
 * @LastEditTime: 2021-06-27 18:17:19
 * @Description: 修改导航模态框
-->

<template>
  <div class="editnavmodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="导航名称" prop="name">
          <el-input clearable class="cl-dialog" v-model="ruleForm.name" placeholder="必填，请输入导航名称"></el-input>
        </el-form-item>
        <el-form-item label="导航类型" prop="type">
          <el-input clearable class="cl-dialog" v-model="ruleForm.type" placeholder="必填，请输入导航类型标题"></el-input>
        </el-form-item>
        <!-- <el-form-item label="导航图标" prop="type">
          <div class="select-input" @click.stop="chooseIcon">
            请选择图标
            <SelectIcon :selectIsShow="selectIsShow" />
          </div>
        </el-form-item> -->
        <el-form-item label="跳转路径" prop="path">
          <el-input clearable class="cl-dialog" v-model="ruleForm.path" placeholder="必填,请输入导航跳转地址"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { editNav } from '@/api/nav';

export default {
  data(){
    return {
      ruleForm: {
        name: '',    //导航名称
        type: '',    //导航类型
        path: '',    //跳转的路径
      },

      rules: {
        name: [
          { required: true, message: '导航名称不能为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '导航图标不能为空', trigger: 'blur' },
        ],
        path: [
          { required: true, message: '跳转路径不能为空', trigger: 'blur' },
        ],
      },

      selectIsShow: false
    }
  },

  methods: {
    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await editNav(this.ruleForm.id, this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: '修改成功'
            })
            this.close('refresh');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    closeModel(){
      this.close();
    },

    close(data){
      this.$emit('closeEditNavModel', false, data);
      this.$refs['ruleForm'].resetFields();
    },

    firstTo(data){
      this.ruleForm = this.$utils.deepClone(data);
    }
  },

  props: {
    editInfo: {
      type: Object,
      required: true
    }
  },

  watch: {
    editInfo: {
      handler(val){
        console.log(val);
        this.firstTo(val);
      }
    }
  },

  created(){
    this.firstTo(this.editInfo);
  }
}
</script>

<style lang="less" scoped>

</style>
