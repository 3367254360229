<!--
 * @Author: CL
 * @Date: 2021-06-23 11:28:51
 * @LastEditTime: 2021-07-22 12:52:49
 * @Description: 导航功能后台管理页面
-->

<template>
  <div class="navback-contain cl-wrap">
    <div class="cl-table-header">
      <el-row style="width: 100%" :gutter="20">
        <el-col :span="6">
          <el-input class="cl-input" v-model="searchForm.name" placeholder="请输入导航名称"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="medium" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>导航信息</div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddNavModel">添加</el-button>
      </div>
      <el-table
        :data="navList"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="name"
          label="导航名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="图标类型"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="path"
          label="跳转路径"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>

    <Dialog 
      :isShow="addNavModel" 
      title="添加导航" 
      :isClick="true"
    >
      <addNav @closeAddNavModel="closeAddNavModel" />
    </Dialog>

    <Dialog 
      :isShow="editNavModel" 
      title="修改导航"
    >
      <editNav @closeEditNavModel="closeEditNavModel" :editInfo="editInfo" :key="key" />
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import { queryNavByPage, delNav } from '@/api/nav';
import IconFont from '@/components/IconFont';
import addNav from './components/addNavModel.vue';
import editNav from './components/editNvaModel.vue';

export default {
  data(){
    return {
      searchForm: {
        name: '',
      },

      tableData: [],

      loading: false,
      page: 1,
      size: 10,
      total: 0,
      addNavModel: false,
      editNavModel: false,
      key: '',
      editInfo: {}
    }
  },

  computed: {
    navList(){
      return this.tableData.map(item => {
        return {
          ...item,
          type: <IconFont type={item.type} />
        }
      });
    }
  },

  methods: {
    /**
    * 搜索          
    */
    handleSearch(){

    },

    /**
     * size发生改变
     */
    handleSizeChange(val){
      this.size = val;
    },

    /**
     * page发生改变
     */
    handleCurrentChange(val){
      this.page = val;
    },

    /**
     * 打开添加导航模块
     */
    openAddNavModel(){
      this.addNavModel = true;
    },

    /**
     * 关闭添加导航的模块
     */
    closeAddNavModel(flag, isRefresh){
      this.addNavModel = flag;
      if(isRefresh == 'refresh'){
        this.queryNav();
      }
    },

    /**
     * 打开修改导航的模态框
     */
    handleEdit(data){
      this.editInfo = this.tableData[data];
      this.editNavModel = true;
    },

    /**
     * 关闭修改导航的模态框
     */
    closeEditNavModel(flag, isRefresh){
      this.editNavModel = flag;
      this.key = new Date().getTime();
      if(isRefresh == 'refresh'){
        this.queryNav();
      }
    },

    /**
     * 删除导航信息
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除此导航信息吗?').then(async () => {
        const res = await delNav(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryNav();
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    },

    /**
     * 分页查询导航数据
     */
    async queryNav(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await queryNavByPage(page, size);
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.tableData = rows;
        }
      }catch(err){
        console.log(err);
      }
    }
  },

  created(){
    this.queryNav();
  },

  components: {
    Pagination,
    IconFont,
    addNav,
    Dialog,
    editNav
  }
}
</script>

<style lang="less" scoped>

</style>
